<template>
  <v-layout>
    <OrderProposalAppBar />

    <v-container fluid class="px-1 fill-height">
      <v-row dense align="center" justify="center" class="mx-1 fill-height">
        <v-col cols="auto">
          <v-card width="100%" v-if="filteredManufacturers.length === 0">
            <v-card-title>
              keine Bestellvormerkungen vorhanden
            </v-card-title>
            <v-card-text class="subtitle-2 text--primary">
              <v-row dense align="center">
                <v-col cols="auto">
                  <span>Bestellvormerkungen werden:</span>
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col cols="auto" class="pl-3 pr-2">
                  <span
                    >- manuell über die Artikelanzeige oder<br />
                    (Artikel > Artikel anzeigen > Bestellen)</span
                  >
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col cols="auto" class="pl-3 pr-2">
                  <span
                    >- automatisch durch Unterschreiten des Mindestbestands
                    <br />
                    (Termine > Neu > Artikelverkauf)</span
                  >
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col cols="auto">
                  <span>erzeugt!</span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pa-2">
              <v-row dense align="center">
                <v-col cols="auto">
                  <v-btn outlined color="primary" to="/wws/database/articles">zu den Artikeln</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-btn outlined color="primary" to="/wws/appointments/table">zu den Terminen</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
          <v-expansion-panels mandatory>
            <v-expansion-panel v-for="item in filteredManufacturers" :key="item.Hersteller_ID">
              <v-expansion-panel-header>
                <template v-slot:default="{ open }">
                  <v-row dense align="center">
                    <v-col cols="auto" class="text-no-wrap">
                      <span class="font-weight-bold text-no-wrap text-subtitle-1 d-none d-md-flex">
                        {{ item.Hersteller }}
                      </span>
                      <span class="font-weight-bold text-no-wrap text-subtitle-1 d-flex">
                        {{ "[" + item.Abkürzung + "]" }}
                      </span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="text--secondary"
                      ><v-fade-transition leave-absolute>
                        <span v-if="open" key="0" class="text--primary">
                          <v-row dense align="center" class="mr-2">
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                              <v-btn
                                :disabled="!item.Shop"
                                outlined
                                color="green lighten-2"
                                :href="linkVisitShop(item.Shop)"
                                target="_blank"
                                @click.stop=""
                                ><v-icon>mdi-basket-outline</v-icon>
                                <span class="ml-1 d-none d-md-flex" v-if="item.Shop">Shop besuchen</span>
                                <span class="ml-1 d-none d-md-flex" v-else>kein Shop hinterlegt</span>
                              </v-btn>
                            </v-col>
                            <v-col cols="auto">
                              <v-menu offset-y nudge-left :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-bind="attrs" v-on="on" outlined color="primary lighten-1"
                                    ><v-icon>mdi-card-account-phone-outline</v-icon>
                                    <span class="ml-1 d-none d-md-flex">Kontakt anzeigen</span>
                                  </v-btn>
                                </template>
                                <v-card width="330">
                                  <v-card-title><span>Kontaktdaten:</span> </v-card-title>
                                  <v-divider></v-divider>
                                  <v-card-text class="text--primary subtitle-2">
                                    <v-row dense align="center">
                                      <v-col v-if="item.Kontaktperson" cols="12" class="font-weight-bold">{{ item.Kontaktperson }}</v-col>
                                      <v-col v-if="item.Kundennummer" cols="3">
                                        <span class="font-weight-bold">Kd-Nr.: </span>
                                      </v-col>
                                      <v-col v-if="item.Kundennummer" cols="9">
                                        <span>{{ item.Kundennummer }}</span>
                                      </v-col>
                                      <v-col v-if="item.Telefon" cols="3">
                                        <span class="font-weight-bold">Tel.: </span>
                                      </v-col>
                                      <v-col v-if="item.Telefon" cols="9">
                                        <span>{{ item.Telefon }}</span>
                                      </v-col>
                                      <v-col v-if="item.Email" cols="3">
                                        <span class="font-weight-bold">Email: </span>
                                      </v-col>
                                      <v-col v-if="item.Email" cols="9">
                                        <span>{{ item.Email }}</span>
                                      </v-col>
                                      <v-col v-if="item.Website" cols="3">
                                        <span class="font-weight-bold">Website: </span>
                                      </v-col>
                                      <v-col v-if="item.Website" cols="9">
                                        <v-btn icon outlined :href="linkVisitShop(item.Website)" target="_blank"><v-icon>mdi-web</v-icon></v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  <v-card-actions> </v-card-actions>
                                </v-card>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </span>
                        <span v-else key="1" class="text--primary text-no-wrap">
                          <span class="font-weight-bold">
                            Artikelanzahl:
                          </span>
                          <span class="text-no-wrap">
                            {{ item.Bestellvormerkungen }}
                          </span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="subtitle-2"
                ><v-divider></v-divider>
                <v-container
                  fluid
                  class="pb-2"
                  v-for="orderproposal in filteredProposals(item.Hersteller_ID)"
                  :key="orderproposal.Bestellvormerkung_ID"
                >
                  <div>
                    <v-row dense align="center" class="pb-1" v-if="item.Hersteller_ID === orderproposal.Hersteller_ID">
                      <v-col cols="12" xl="10" lg="10" md="10">
                        <v-row dense align="center">
                          <v-col cols="auto">
                            <span class="font-weight-bold">Art.Nr.: </span>
                            <span>{{ orderproposal.Artikelnummer }}</span>
                            <span class="red--text" v-if="orderproposal.manuell_vorgemerkt === 0">
                              (automatisch vorgemerkt!)
                            </span>
                            <span v-if="orderproposal.manuell_vorgemerkt === 1">
                              (manuell vorgemerkt)
                            </span>
                          </v-col>
                          <v-col cols="auto">
                            <v-btn icon rounded outlined @click="linkShowArticle(orderproposal.Artikel_ID)"
                              ><v-icon>mdi-tshirt-crew-outline</v-icon></v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-row dense align="center">
                          <v-col cols="12" xl="3" lg="6" md="6" sm="6">
                            <span class="font-weight-bold">Größe: </span>
                            <span>{{ orderproposal.Größe }}</span>
                          </v-col>
                          <v-col cols="12" xl="3" lg="6" md="6" sm="6">
                            <span class="font-weight-bold">Farben: </span>
                            <span>{{ orderproposal.Farben }} </span>
                          </v-col>
                          <v-col cols="12" xl="3" lg="6" md="6" sm="6">
                            <span class="font-weight-bold">Stoffe: </span>
                            <span>{{ orderproposal.Stoffe }}</span>
                          </v-col>
                          <v-col cols="12" xl="3" lg="6" md="6" sm="6">
                            <span class="font-weight-bold">Schnitt: </span>
                            <span>{{ orderproposal.Schnitt }}</span>
                          </v-col>
                        </v-row>
                        <v-row dense align="center">
                          <v-col cols="12" xl="3" lg="6" md="6" sm="6">
                            <span class="font-weight-bold">Bestellmenge(*)</span>
                            <v-text-field
                              v-model="orderproposal.Bestellmenge"
                              hide-details
                              dense
                              outlined
                              clearable
                              required
                              :placeholder="String(orderproposal.zu_bestellende_Menge)"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" xl="3" lg="6" md="6" sm="6">
                            <span class="font-weight-bold">EK netto(*)</span>
                            <v-text-field
                              v-model="orderproposal.EK_netto_bestellt"
                              hide-details
                              dense
                              outlined
                              clearable
                              required
                              :placeholder="orderproposal.EK_netto"
                              suffix="€"
                              @change="formatEKnetto($event, orderproposal, orderproposal.EK_netto_bestellt)"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" xl="3" lg="6" md="6" sm="6">
                            <span class="font-weight-bold">Lieferzeitraum</span>
                            <v-menu
                              v-model="orderproposal.datepicker1"
                              :close-on-content-click="false"
                              :nudge-right="30"
                              transition="scale-transition"
                              offset-y
                              min-width="290"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="orderproposal.Lieferzeitraum"
                                  readonly
                                  outlined
                                  dense
                                  hide-details
                                  clearable
                                  placeholder="Zeitraum"
                                  @click:clear="$nextTick(() => resetDate1(orderproposal))"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="orderproposal.date1"
                                locale="de"
                                full-width
                                no-title
                                range
                                @change="dateRange($event, orderproposal, orderproposal.date1)"
                                type="month"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" xl="3" lg="6" md="6" sm="6">
                            <span class="font-weight-bold">Lieferdatum</span>
                            <v-menu
                              v-model="orderproposal.datepicker2"
                              :close-on-content-click="false"
                              :nudge-right="30"
                              transition="scale-transition"
                              offset-y
                              min-width="290"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="orderproposal.Lieferdatum"
                                  readonly
                                  outlined
                                  dense
                                  hide-details
                                  clearable
                                  placeholder="Datum"
                                  @click:clear="$nextTick(() => resetDate2(orderproposal))"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="orderproposal.date2"
                                @input="orderproposal.datepicker2 = false"
                                locale="de"
                                first-day-of-week="1"
                                full-width
                                no-title
                                @change="dateExact($event, orderproposal, orderproposal.date2)"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="12" xl="auto" lg="auto" md="auto">
                        <v-row dense align="center" justify="end">
                          <v-col cols="6" xl="12" lg="12" md="12" class="text-center">
                            <v-btn
                              color="green lighten-2"
                              icon
                              outlined
                              :disabled="
                                !orderproposal.Bestellmenge ||
                                  !orderproposal.EK_netto_bestellt ||
                                  (!orderproposal.Lieferdatum && !orderproposal.Lieferzeitraum)
                              "
                              large
                              @click="openOrder(orderproposal, item)"
                              ><v-icon>mdi-cart-check</v-icon></v-btn
                            >
                          </v-col>
                          <v-col cols="6" xl="12" lg="12" md="12" class="text-center">
                            <v-btn
                              color="error"
                              icon
                              large
                              outlined
                              rounded
                              :disabled="orderproposal.verpflichtend === 1"
                              @click="openCancelOrderProposal(orderproposal, item)"
                              ><v-icon>mdi-cart-remove</v-icon></v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-2"></v-divider>
                  </div>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <CancelOrderProposal ref="CancelOrderProposal" @confirmCancelOrderProposal="removeOrderProposal" />
    <Order ref="Order" @confirmOrder="saveOrder" />
  </v-layout>
</template>

<script>
import OrderProposalAppBar from "../../components/appbars/OrderProposalAppBar.vue";
import CancelOrderProposal from "./dialogs/CancelOrderProposal.vue";
import Order from "./dialogs/Order.vue";

export default {
  components: {
    OrderProposalAppBar,
    CancelOrderProposal,
    Order,
  },

  mounted() {
    this.initialize();
  },

  data() {
    return {
      dataset: [],
      manufacturers: [],
      completedProposals: [],
      canceledProposals: [],
    };
  },

  computed: {
    filteredByCompletedProposals() {
      if (this.completedProposals.length > 0) {
        return this.dataset.filter((it) => this.completedProposals.indexOf(it.Bestellvormerkung_ID) === -1);
      } else return this.dataset;
    },

    filteredByCanceledProposals() {
      if (this.canceledProposals.length > 0) {
        return this.filteredByCompletedProposals.filter((it) => this.canceledProposals.indexOf(it.Bestellvormerkung_ID) === -1);
      } else return this.filteredByCompletedProposals;
    },

    filteredManufacturers() {
      return this.manufacturers.filter((it) => it.Bestellvormerkungen > 0);
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/${this.$route.meta.request}/orderproposals`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }
    },

    filteredProposals(id) {
      if (id) {
        return this.filteredByCanceledProposals.filter((item) => item.Hersteller_ID === id);
      } else return [];
    },

    linkVisitShop(shop) {
      if (shop) {
        return `https://${shop}/`;
      }
    },

    linkShowArticle(id) {
      if (id) {
        this.$router.push({
          path: `/wws/database/articles/dataset/show/${id}`,
        });
      }
    },

    formatEKnetto(e, item, preis) {
      if (preis) {
        const leihpreis_string = String(parseFloat(preis.replace(",", ".")).toFixed(2)).replace(".", ",");
        item.EK_netto_bestellt = leihpreis_string;
      }
    },

    dateRange(e, item, datum) {
      if (item.Lieferdatum) {
        item.Lieferdatum = null;
        item.date2 = null;
        item.letzter_Zustelltag = null;
      }
      if (datum[0] > datum[1]) {
        const [firstyear, firstmonth] = datum[1].split("-");
        const firstdate = `${firstmonth}/${firstyear}`;
        const [secondyear, secondmonth] = datum[0].split("-");
        const seconddate = `${secondmonth}/${secondyear}`;
        const lieferzeitraum = `${firstdate} - ${seconddate}`;
        item.Lieferzeitraum = lieferzeitraum;

        const dayafter = new Date(secondyear, secondmonth, 1);
        const lastday = new Date(dayafter - 1).toISOString().substring(0, 10);

        Object.assign(item, { letzter_Zustelltag: lastday });
      } else {
        const [firstyear, firstmonth] = datum[0].split("-");
        const firstdate = `${firstmonth}/${firstyear}`;
        const [secondyear, secondmonth] = datum[1].split("-");
        const seconddate = `${secondmonth}/${secondyear}`;
        const lieferzeitraum = `${firstdate} - ${seconddate}`;
        item.Lieferzeitraum = lieferzeitraum;

        const dayafter = new Date(secondyear, secondmonth, 1);
        const lastday = new Date(dayafter - 1).toISOString().substring(0, 10);

        Object.assign(item, { letzter_Zustelltag: lastday });
      }
    },

    dateExact(e, item, datum) {
      if (item.Lieferzeitraum) {
        item.Lieferzeitraum = null;
        item.date1 = [];
        item.letzter_Zustelltag = null;
      }
      if (datum) {
        const [year, month, day] = datum.split("-");
        const lieferdatum = `${day}.${month}.${year}`;
        item.Lieferdatum = lieferdatum;

        Object.assign(item, { letzter_Zustelltag: datum });
      }
    },

    resetDate1(item) {
      item.Lieferzeitraum = null;
      item.date1 = [];
      item.letzter_Zustelltag = null;
    },

    resetDate2(item) {
      item.Lieferdatum = null;
      item.date2 = null;
      item.letzter_Zustelltag = null;
    },

    openCancelOrderProposal(item, manufacturer) {
      this.$refs.CancelOrderProposal.open(item, manufacturer);
    },

    openOrder(item, manufacturer) {
      this.$refs.Order.open(item, manufacturer);
    },

    async removeOrderProposal(item, manufacturer) {
      const datasetToRemove = {};

      if (Object.keys(datasetToRemove).length === 0) {
        Object.assign(datasetToRemove, {
          Artikel_ID: item.Artikel_ID,
          Bestellvormerkung_ID: item.Bestellvormerkung_ID,
          Bestellstatus_ID: item.Bestellstatus_ID,
          Artikelname_ID: item.Artikelname_ID,
        });
      }

      if (item.Status_ID === 3) {
        await fetch(`/api/database/articles/dataset/name/available`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datasetToRemove),
        });
      }

      await fetch(`/api/${this.$route.meta.request}/orderproposal/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToRemove),
      });

      await fetch(`/api/${this.$route.meta.request}/article/orderstatus/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToRemove),
      });

      this.canceledProposals.push(item.Bestellvormerkung_ID);

      const objIndex = this.manufacturers.findIndex((obj) => obj.Hersteller_ID == manufacturer.Hersteller_ID);
      this.manufacturers[objIndex].Bestellvormerkungen = this.manufacturers[objIndex].Bestellvormerkungen - 1;

      this.$root.actionSnackbar.show({
        dataset: "Vorgemerkte Bestellung",
        action: "verworfen",
      });

      setTimeout(() => {
        this.$root.mainmenu.reinitialize();
      }, 500);
    },

    async saveOrder(item, manufacturer) {
      const datasetToOrder = {};

      if (Object.keys(datasetToOrder).length === 0) {
        Object.assign(datasetToOrder, {
          Artikel_ID: item.Artikel_ID,
          Bestellvormerkung_ID: item.Bestellvormerkung_ID,
          Bestellstatus_ID: 4,
          Status_ID: 2,
          Lieferdatum: item.Lieferdatum || null,
          Lieferzeitraum: item.Lieferzeitraum || null,
          EK_netto: item.EK_netto_bestellt,
          Bestellmenge: item.Bestellmenge,
          letzter_Zustelltag: item.letzter_Zustelltag,
          wurde_zugestellt: 0,
          Kunden_ID: null,
          Termin_ID: null,
          bestellt_durch: item.Bearbeiter_ID,
          Vorgang_ID: 6,
          Bestand: Number(item.Bestellmenge) + item.Bestand,
          Artikelname_ID: item.Artikelname_ID,
        });
      }

      const saveresponse = await fetch(`/api/${this.$route.meta.request}/order/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToOrder),
      });
      if (item.Artikelname_ID !== null) {
        await fetch(`/api/database/articles/name/available`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datasetToOrder),
        });
      }

      const jsonartikel = await saveresponse.json();
      const neworderid = jsonartikel.lastInsertRowid;

      const orderEventToAdd = {};

      if (Object.keys(orderEventToAdd).length === 0) {
        Object.assign(orderEventToAdd, {
          Vorgang_ID: 6,
          Bestellung_ID: neworderid,
          Bearbeiter_ID: item.Bearbeiter_ID,
        });
      }

      await fetch(`/api/${this.$route.meta.request}/order/event/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderEventToAdd),
      });

      await fetch(`/api/${this.$route.meta.request}/orderproposal/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToOrder),
      });

      await fetch(`/api/${this.$route.meta.request}/article/orderstatus/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToOrder),
      });

      const datasetEventToAdd = {};

      if (Object.keys(datasetEventToAdd).length === 0) {
        Object.assign(datasetEventToAdd, {
          Vorgang_ID: 6,
          Artikel_ID: item.Artikel_ID,
          Bestellung_ID: neworderid,
          Termin_ID: null,
          Kunden_ID: null,
          Bearbeiter_ID: item.Bearbeiter_ID,
        });
      }

      await fetch(`/api/database/articles/dataset/event/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetEventToAdd),
      });

      this.completedProposals.push(item.Bestellvormerkung_ID);

      const objIndex = this.manufacturers.findIndex((obj) => obj.Hersteller_ID == manufacturer.Hersteller_ID);
      this.manufacturers[objIndex].Bestellvormerkungen = this.manufacturers[objIndex].Bestellvormerkungen - 1;

      this.$root.actionSnackbar.show({
        dataset: "Bestellung",
        action: "erfasst",
      });

      setTimeout(() => {
        this.$root.mainmenu.reinitialize();
      }, 500);
    },
  },
};
</script>
