<template>
  <v-dialog v-model="OrderArticle" width="475px" overlay-opacity="0.75" scrollable @click:outside="clearAbweichung()">
    <v-card tile class="pa-3">
      <v-card-title class="px-1"
        ><v-col cols="12" class="pa-0">
          <v-row dense class="align-center">
            <v-col cols="auto">
              <v-toolbar-title>Bestellung überprüfen</v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>
      <v-card-text class="text--primary py-5 px-1 subtitle-2">
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-col cols="12">
              Bitte folgende Daten überprüfen und bestätigen:
            </v-col>
          </v-row>
          <v-row dense align="center" class="subtitle-2 py-2">
            <v-col cols="4" offset="1" class="font-weight-bold">Artikelnummer:</v-col>
            <v-col cols="7">{{ dataset.Artikelnummer }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold">Größe:</v-col>
            <v-col cols="7">{{ dataset.Größe }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold">Farben:</v-col>
            <v-col cols="7">{{ dataset.Farben }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold">Stoffe:</v-col>
            <v-col cols="7">{{ dataset.Stoffe }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold">Schnitt:</v-col>
            <v-col cols="7">{{ dataset.Schnitt }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold">Hersteller:</v-col>
            <v-col cols="7">{{ datasetManufacturer.Hersteller }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold">Bestellmenge:</v-col>
            <v-col cols="7">{{ dataset.Bestellmenge }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold pb-0">EK netto:</v-col>
            <v-col cols="7" class="pb-0">
              <span>{{ dataset.EK_netto_bestellt + " €" }}</span>
            </v-col>
            <v-col cols="11" offset="1" class="pt-0">
              <span class="red--text text--lighten-2" v-if="checkAbweichung < 0"
                >{{ "(Bestellung ist " + String(Abweichung.toFixed(2)).replace(".", ",") + " € teurer als EK netto des Artikels)" }}
              </span>
              <span class="green--text text--lighten-2" v-if="checkAbweichung > 0">{{
                "(Bestellung ist " + String(Abweichung.toFixed(2)).replace(".", ",") + " € günstiger als EK netto des Artikels)"
              }}</span></v-col
            >
            <v-col v-if="dataset.Lieferdatum" cols="4" offset="1" class="font-weight-bold">Lieferdatum:</v-col>
            <v-col v-if="dataset.Lieferdatum" cols="7">{{ dataset.Lieferdatum }}</v-col>
            <v-col v-if="dataset.Lieferzeitraum" cols="4" offset="1" class="font-weight-bold">Lieferzeitraum:</v-col>
            <v-col v-if="dataset.Lieferzeitraum" cols="7">{{ dataset.Lieferzeitraum }}</v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12">
              <span class="font-weight-bold">Bearbeiter(*)</span>
              <v-select
                hide-details
                dense
                outlined
                required
                placeholder="Bearbeiter(*)"
                v-model="dataset.Bearbeiter_ID"
                :items="Bearbeiter"
                item-text="Vorname"
                item-value="Bearbeiter_ID"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn outlined color="red lighten-1" @click="abortOrderDataset()">
                <span>Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="green lighten-1" @click="confirmOrderDataset()" :disabled="!dataset.Bearbeiter_ID">
                <v-icon left class="grey--text text--lighten-4">mdi-check </v-icon>
                <span class="grey--text text--lighten-4">Bestellung erfassen</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    Bearbeiter: [],
    dataset: {},
    datasetManufacturer: {},
    OrderArticle: false,
    Abweichung: null,
    checkAbweichung: null,
  }),

  methods: {
    open(item, manufacturer) {
      this.OrderArticle = true;

      this.initialize();

      this.dataset = Object.assign({}, item);
      this.datasetManufacturer = Object.assign({}, manufacturer);
    },

    async initialize() {
      const response = await fetch(`/api/database/articles/dataset/insert`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.calculateAbweichungEKnetto();
    },

    confirmOrderDataset() {
      this.OrderArticle = false;
      this.$emit("confirmOrder", this.dataset, this.datasetManufacturer);
    },

    abortOrderDataset() {
      this.OrderArticle = false;
    },

    calculateAbweichungEKnetto() {
      if (this.dataset.EK_netto !== "" || this.dataset.EK_netto !== null) {
        const eknetto = this.dataset.EK_netto;
        const eknettobestellt = this.dataset.EK_netto_bestellt;

        const eknetto_number = parseFloat(eknetto.replace(",", "."));

        const eknettobestellt_number = parseFloat(eknettobestellt.replace(",", "."));

        const checkabweichung = eknetto_number - eknettobestellt_number;
        const abweichung = Math.abs(eknetto_number - eknettobestellt_number);

        this.checkAbweichung = checkabweichung;
        this.Abweichung = abweichung;
      }
    },

    clearAbweichung() {
      this.checkAbweichung = null;
      this.Abweichung = null;
    },
  },
};
</script>
