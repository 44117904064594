<template>
  <v-dialog v-model="CancelOrderProposal" width="475px" overlay-opacity="0.75" scrollable>
    <v-card tile class="pa-3">
      <v-card-title
        ><v-col cols="12" class="pa-0">
          <v-row dense class="align-center">
            <v-col cols="auto">
              <v-toolbar-title>Vorgemerkte Bestellung verwerfen</v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>
      <v-card-text class="text--primary py-5 px-3 subtitle-2">
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-col cols="12">
              Warum soll die vorgemerkte Bestellung des folgenden Artikels verworfen werden?
            </v-col>
          </v-row>
          <v-row dense align="center" class="subtitle-2 py-2">
            <v-col cols="4" offset="1" class="font-weight-bold">Artikelnummer:</v-col>
            <v-col cols="7">{{ dataset.Artikelnummer }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold">Größe:</v-col>
            <v-col cols="7">{{ dataset.Größe }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold">Farben:</v-col>
            <v-col cols="7">{{ dataset.Farben }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold">Stoffe:</v-col>
            <v-col cols="7">{{ dataset.Stoffe }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold">Schnitt:</v-col>
            <v-col cols="7">{{ dataset.Schnitt }}</v-col>
            <v-col cols="4" offset="1" class="font-weight-bold">Hersteller:</v-col>
            <v-col cols="7">{{ datasetManufacturer.Hersteller }}</v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12">
              <span class="font-weight-bold">Grund(*)</span>
              <v-select
                placeholder="Grund"
                dense
                hide-details
                outlined
                v-model="dataset.Bestellstatus_ID"
                :items="Gründe"
                item-text="Grund"
                item-value="Bestellstatus_ID"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn outlined color="red lighten-1" @click="abortCancelOrderProposal()">
                <span>Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="green lighten-1" @click="confirmCancelOrderProposal()" :disabled="dataset.Bestellstatus_ID === 3">
                <v-icon left class="grey--text text--lighten-4">mdi-check </v-icon>
                <span class="grey--text text--lighten-4">Verwerfen</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dataset: {},
    datasetManufacturer: {},
    CancelOrderProposal: false,
    Gründe: [
      { Grund: "Artikel derzeit nicht benötigt", Bestellstatus_ID: 2 },
      { Grund: "Artikel nicht mehr bestellbar", Bestellstatus_ID: 1 },
    ],
  }),

  methods: {
    open(item, manufacturer) {
      this.CancelOrderProposal = true;
      this.dataset = Object.assign({}, item);
      this.datasetManufacturer = Object.assign({}, manufacturer);
    },

    confirmCancelOrderProposal() {
      this.CancelOrderProposal = false;
      this.$emit("confirmCancelOrderProposal", this.dataset, this.datasetManufacturer);
    },

    abortCancelOrderProposal() {
      this.CancelOrderProposal = false;
    },
  },
};
</script>
